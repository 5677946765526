<template lang="pug">
div
  v-tabs(
    show-arrows
    next-icon="mdi-arrow-right-bold-box-outline"
    prev-icon="mdi-arrow-left-bold-box-outline")
    v-tabs-slider(color="primary")
    div.d-flex.align-items-center(v-for="tab in tabs" :key="tab.id")
      v-tab(:to="tab.link" v-if="tab.hasOwnProperty('checkAccess') ? tab.checkAccess() : true").p-2 {{$t(tab.label)}}
  router-view

</template>

<script>
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'Settings',
  data () {
    return {
      checkAccess,
      tabs: [
        { id: 1, label: 'keysSign', link: { name: 'sign-key' }, checkAccess: () => checkAccess('backOffice') },
        { id: 2, label: 'user-setting', link: { name: 'user-setting' } }
        // { id: 3, label: 'globalConfigSystem', link: { name: 'global-config-system' }, checkAccess: () => checkAccess('backOffice') } // functional for change 2fa access for all users system
      ]
    }
  }
}
</script>
